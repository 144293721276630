import React from 'react';
import "./GolfDisplayComponent.css";

const GolfDisplayComponent = ({ golfData }) => {
    const getTeeStyle = (tee) => {
        switch (tee) {
            case 0:
                return { backgroundColor: '#ffffff' };
            case 1:
                return { backgroundColor: '#FFE002' };
            case 2:
                return { backgroundColor: '#0701C4' };
            case 3:
                return { backgroundColor: '#DD0101' };
            case 4:
                return { backgroundColor: '#EA8B02' };
            default:
                return {};
        }
    };

    return (
        <div className="flex flex-top">
            <div className={`card-logo ${golfData.length > 0 ? 'small' : 'big'}`}>
                <div className="card-logo--logo"></div>
            </div>
            {golfData.length > 0 ? (
                <div className="header-text header-text-end">
                    <span>Geniet van het golfen,</span>
                    <span>speel van de juiste tee!</span>
                    <span className="header-text--end-span">Op basis van uw invoer, raden we aan te spelen vanaf:</span>
                </div>
            ) : (
                <div className="header-text header-text-begin">
                    <span className="header-text--first-span">Welkom bij Hooge Graven</span>
                    <span className="header-text--second-span">Bereken uw baanhandicap</span>
                </div>
            )}

            {golfData.length > 0 ? (
                <>
                    {golfData.map((teeData, index) => (
                        teeData.tee === index ? (
                            <div key={index} className="handicap-card">
                                <div className="handicap-card--color" style={getTeeStyle(index)}></div>
                                <div className="handicap-card--meter">
                                    <span>METER</span>
                                    <span>{teeData.meter}</span>
                                </div>
                                <div className="handicap-card--handicap">
                                    <span>BAANHCP.</span>
                                    <span>{teeData.adjustedHandicap}</span>
                                </div>
                            </div>
                        ) : null
                    ))}

                    <div className="handicap-results">
                        <div className="handicap-results--information">
                            <div>BAANHCP.</div>
                            <div>METER</div>
                        </div>
                        <div className="handicap-results--results">
                            {golfData.map((teeData, index) => (
                                // teeData.cr !== 0 && teeData.sr !== 0 && teeData.par !== 0 ? (
                                <div key={index}>
                                    <div style={getTeeStyle(index)} className="handicap-results--color"></div>
                                    <div className="handicap-results-flex">
                                        <span className="handicap-results--span">{teeData.adjustedHandicap}</span>
                                        <span className="handicap-results--span">{teeData.meter}</span>
                                    </div>
                                </div>
                                // ) : null
                            ))}
                        </div>
                    </div>
                </>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default GolfDisplayComponent;
