// Author: Menno van Emmerik
// Date(sortof): 2019/2020
// Mail: menno@viewiemedia.nl


import React, { useState } from "react";
import { connect } from "react-redux";
import HousePage from "./pages/HousePage.js";
import MainPage from "./pages/MainPage.js";
// import MapPage from "./pages/mapPage/MapPage.js";
import ImgAlbumPage from "./pages/image_list/ImageList.js";
import { bindActionCreators } from "redux";
import { updateOrganisation } from "./store/actions";
import { updateAllHouses } from "./store/actions";
import { updateDisplayedHouseList } from "./store/actions";
import { useHistory, useLocation } from "react-router-dom";
import { filterSelector } from "./store/reducers/reducer-displayed-houses";
import "./App.css";
import { updateAppStatus } from "./store/actions/index";
import fetchJsonp from "fetch-jsonp";

function App(props) {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();


    window.onload = function () {
        var timestart = query.get("timeout");
        try {
            if (this.viewiemedia_clientCall) {

                props.updateAppStatus(this.viewiemedia_clientCall);


                //alert('startimer met ' + timestart)
                if (parseInt(timestart)) {
                    inactivityTime(timestart);
                }
            }
        } catch (e) { }
    };

    var inactivityTime = function (timestart) {
        var time;
        document.onload = resetTimer;
        document.onmousemove = resetTimer;
        document.onmousedown = resetTimer;
        document.ontouchstart = resetTimer;
        document.onclick = resetTimer;
        document.onkeypress = resetTimer;
        document.addEventListener("scroll", resetTimer, true);

        function exitt() {
            try {
                this.viewiemedia_clientCall.exitTouchMode();
            } catch (e) {
                // console.log(e);
            }

            //      alert(timestart);

            clearTimeout(time);
        }

        function resetTimer() {
            clearTimeout(time);
            time = setTimeout(exitt, timestart);
            // 1000 milliseconds = 1 second
        }
    };

    //Gets called every url change
    //Gets id from url -> uses id(numeric) to get API data -> if needed add fetched API_organisation_name to the url
    let history = useHistory();

    const [isEverythingLoaded, setIsEverythingLoaded] = useState(null);

    const firstPartUrl = window.location.pathname.split("/")[1];

    const id = firstPartUrl.replace(/ *\([^)]*\) */g, "").replace(/\D/g, ""); //Removes the parentheses from firstpart url so that you get only the id(which we use to get api data) / removes all non-numerics aswell

    if (!id) {
        //any id left?
        //  alert("Geen organisatie gevonden")

        history.push("/login");
        return null;
    } else {
        if (isEverythingLoaded === null) {
            //get from api
            getAPIdata(id);
            async function getAPIdata(id) {
                // alert(id)
                // function minimumTime() {
                //     //To prevent the openingloadingvideo from getting interrupted (minimumTime that the video will play)
                //     return new Promise(resolve => {
                //         setTimeout(() => {
                //             resolve();
                //         }, 10); //3500
                //     });
                // }
                //      function preload_images(allHouses){
                //          for (let i = 0; i < allHouses;i++ ){
                //              for(let y = 0;y< allHouses[i]; y++){
                //                  var img = new Image();
                //                  img.src = allHouses[i].media[y].url;
                //              }
                //          }
                //      }
                //https://restcountries.eu/rest/v2/all
                //https://app.viewiemedia.nl/api/organisations/2?apikey=ghty6s9e9sjfa97asf6302rafaefb32s

                try {
                    // const [response] = await Promise.all([fetch(url), minimumTime()]); //Loadingscreen only stops after Fetch and Introvideo are both completed



                    const response = await fetchJsonp(`https://app.viewiemedia.nl/api/organisations/${id}?apikey=ghty6s9e9sjfa97asf6302rafaefb32s&callback=callback`, {
                        jsonpCallbackFunction: 'callback'
                    });

                    const data = await response.json();       
                    console.log(data);    
                    if (Object.entries(data).length > 0) {

                        // var ordering = "hoog-laag"; //data.organisation.ordering
                        // var putSoldAtTheEnd = true;

                        function sortOnPrice(a, b) {
                            if (a.price < b.price) {
                                return -1;
                            }
                            if (a.price > b.price) {
                                return 1;
                            }
                            return 0;
                        }

                        // Dit is als het ooit aanpasbaar moet zijn
                        // if (false && data.organisation.ordering === "laag-hoog" || true) {
                        //   data.properties = data.properties.sort(sortOnPrice);
                        // }
                        // else if (data.organisation.ordering === "hoog-laag") {
                        //   data.properties = data.properties.sort(sortOnPrice).reverse();
                        // }
                        
                        function isVerkochtVerhuurd(obj = {}){
                            switch(obj.sale_type + " "+obj.sale_status ){
                                case "1 4": //verkocht
                                    return true;
                                case "2 5": //verhuurd
                                    return true; 
                                case "1 3": //verkocht ov
                                    return true;
                                case "2 6": //verhuurd ov
                                    return true;
                                default:
                                     return false;
                            }
                        }
                        

                        //Hieronder eigen filter (nu hardcode ingesteld) die eerst prijs hoog-laag doet maar vervolgens de verkochtte/verhuurde huizen achteraan zet
                            //Verdeel huizenarray in 2 groepen

                        var sort_array_first = [];//not sold yet
                        var sort_array_second = []; //sold
                        for(let i = 0; i<data.properties.length;i++){
                            if(isVerkochtVerhuurd(data.properties[i])){
                                sort_array_second.push(data.properties[i])
                            }
                            else {
                                sort_array_first.push(data.properties[i]);
                            }
                        }
                            //Allebei sortopPrice hoog-laag
                            sort_array_first = sort_array_first.sort(sortOnPrice).reverse();
                            sort_array_second = sort_array_second.sort(sortOnPrice).reverse();
                            //Plak ze achter elkaar
                            data.properties = sort_array_first.concat(sort_array_second);


                        //Changes to the organisation data before using it


                        data.organisation.logo = "https://app.viewiemedia.nl/storage/" + data.organisation.media[0];
                        //Organisation data now in use
                        data.organisation.id = id;
                        props.updateOrganisation(data.organisation);
                        // ordering: "hoog-laag",
                        // primary_color_text: "#purple",
                        // secondary_color_text: "#purple",
                        // link_color: "#purple",
                        // : "#purple",




                        //Edit houselist before it gets used
                        for (let i = 0; i < data.properties.length; i++) {
                            //Removes useless houses
                            if (data.properties[i].price === '0' || data.properties[i].price === 0 || !data.properties[i].price) {
                                data.properties.splice(i, 1)
                            }
                        }
                        for (let i = 0; i < data.properties.length; i++) {
                            //makes number of rooms 0 if house_type = land
                            if (data.properties[i].og === "LAND") {
                                data.properties[i].number_of_rooms = null;
                                data.properties[i].bedrooms = null;
                            }
                        }




                        props.updateAllHouses(data.properties);
                        props.updateDisplayedHouseList(filterSelector(data.properties));

                        var organisationName = data.organisation.name;
                        if (
                            organisationName &&
                            firstPartUrl !== id + "(" + organisationName + ")" &&
                            !parseInt(query.get("timeout"))
                        ) {
                            //If possible/needed change url
                            var newFirstPartUrl = id + "(" + organisationName + ")";
                            var newUrl = newFirstPartUrl.split(" ").join("-");

                            for (var i = 2; window.location.pathname.split("/")[i]; i++) {
                                newUrl = newUrl + "/" + window.location.pathname.split("/")[i];
                            }

                            history.push("/" + newUrl, setIsEverythingLoaded(true));
                            //second parameter is State
                            //    history.pushState(setIsEverythingLoaded("ffff"), null, "/" + newUrl);
                            //Will continue to change the state at the beginning of the next(2/3 round)

                            return null;
                        }
                        //This only if name is already correct in first round
                        setIsEverythingLoaded(true);
                    } else {
                        setIsEverythingLoaded(false);
                    }
                } catch (e) {
                    setIsEverythingLoaded(false);
                }
            }

            return (
                <div className="loadingScreenContainer text-center" style={{ paddingTop: "20px" }}>
                    Ophalen huizendata..
                    {/* <video muted autoPlay className="loadingScreen">
            <source
              src={require("./assets/videos/viewie-logo-intro.webm")}
              type="video/webm"
            />
            <source
              src={require("./assets/videos/viewie-logo-intro.mp4")}
              type="video/mp4"
            />
            Loading...
          </video> */}
                </div>
            );
        }

        if (isEverythingLoaded) {
            //E==this gets called every step

            switch (props.page) {
                case "HousePage":
                    return <HousePage></HousePage>;
                case "MainPage":
                    return <MainPage></MainPage>;
                case "ImgAlbumPage":
                    return <ImgAlbumPage></ImgAlbumPage>;
                // case "MapPage":
                //   return <MapPage></MapPage>
                default:
                    return;
            }
        }

        if (isEverythingLoaded === false) {
            //   history.push("/login")
            //  alert("Inladen van data mislukt")
            return "Laden mislukt :(";
        }
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            //What do we write to the redux store
            //<>does that even happend from this file? maybe it does
            updateAllHouses: updateAllHouses,
            updateDisplayedHouseList: updateDisplayedHouseList,
            updateAppStatus: updateAppStatus,
            updateOrganisation: updateOrganisation
        },
        dispatch
    );
}

export default connect("", matchDispatchToProps)(App);
