import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import "./App.css";

import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store";

import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect
} from "react-router-dom";

import LoginPage from "./pages/loginPage/LoginPage";
import GolfPage from "./pages/golfPage/GolfPage";

import App from "./App.js";


const routing = (
  <div id="App">
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path={"/login"} component={LoginPage} />
          <Route exact path={"/golf/handicap"} component={GolfPage} />
          {/* <Route exact path={"/map"} component={mapPage} />  */}
          <Route
            exact
            path={"/:agencyName"}
            render={props => <App {...props} page={"MainPage"} />}
          />
          {/* <Route exact path={"/:agencyName/maps"} render={props => <App {...props} page={"MapPage"}/>} /> */}

          <Route
            exact
            path={"/:agencyName/:houseId"}
            render={props => <App {...props} page={"HousePage"} />}
          />

          <Route
            exact
            path={"/:agencyName/:houseId/images"}
            render={props => <App {...props} page={"ImgAlbumPage"} />}
          />
          <Route
            exact
            path={"/:agencyName/:houseId/images/:imageId"}
            render={props => <App {...props} page={"ImgAlbumPage"} />}
          />

          <Redirect to={"/login"} />
        </Switch>
      </Router>
    </Provider>
  </div>
);

//whichorganisation
//organisation:naam/house:id

ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
