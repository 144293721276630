import React from "react";
import "./GolfPage.css";
import GolfTeeComponent from "../../components/golfTee/GolfTeeComponent";

function GolfPage() {
    // const [golfData, setGolfData] = useState({
    //     totalCr: 0,
    //     totalSr: 0,
    //     totalPar: 0,
    //     adjustedHandicap: 0
    // });
    //
    // const handleTeeSelected = (calculatedData) => {
    //     setGolfData(calculatedData);
    // };

    return (
        <div className="screen">
            {/*<GolfTeeComponent onTeeSelected={handleTeeSelected} />*/}
            <GolfTeeComponent/>
            {/*<GolfDisplayComponent golfData={golfData} />*/}
        </div>
    );
}

export default GolfPage;
