import React, {useState} from 'react';
import GolfDisplayComponent from './GolfDisplayComponent';
import "./GolfTeeComponent.css";

const GolfTeeComponent = () => {

    const courseData = {
        // male: {
        //     '18-holes': {
        //         cr: [73.3, 71.1, 68.9, 66.7, 63.9],
        //         sr: [138, 136, 133, 117, 109],
        //         par: [72, 72, 72, 72, 72]
        //     },
        //     'first-9': {
        //         cr: [36.2, 35.0, 34.5, 33.3, 32.3],
        //         sr: [137, 136, 134, 115, 103],
        //         par: [36, 36, 36, 36, 36]
        //     },
        //     'second-9': {
        //         cr: [37.2, 36.1, 34.4, 33.4, 31.7],
        //         sr: [138, 136, 131, 118, 114],
        //         par: [36, 36, 36, 36, 36]
        //     }
        // },
        male: {
            '18-holes': {
                cr: [73.7, 72.9, 70.1, 68.5, 65.2],
                sr: [141, 137, 127, 122, 110],
                par: [72, 72, 72, 72, 72],
                meter: [6194, 5974, 5407, 5112, 4417]
            },
            'first-9': {
                cr: [35.7, 35.3, 34.3, 33.2, 31.8],
                sr: [131, 133, 128, 115, 106],
                par: [35, 35, 35, 35, 35],
                meter: [2903, 2800, 2601, 2380, 2045]
            },
            'second-9': {
                cr: [38.1, 37.6, 35.9, 35.4, 33.7],
                sr: [151, 142, 126, 128, 115],
                par: [37, 37, 37, 37, 37],
                meter: [3291, 3174, 2806, 2732, 2372]
            },
            'par-9-holes': {
                cr: [0, 0, 0, 24.5, 0],
                sr: [0, 0, 0, 70, 0],
                par: [0, 0, 0, 27, 0],
                meter: [0, 0, 0, 0, 0]
            },
            'par-18-holes': {
                cr: [0, 0, 0, 48.9, 0],
                sr: [0, 0, 0, 70, 0],
                par: [0, 0, 0, 54, 0],
                meter: [0, 0, 0, 0, 0]
            }
        },
        female: {
            '18-holes': {
                cr: [0, 79.4, 75.5, 73.7, 69.6],
                sr: [0, 145, 134, 135, 121],
                par: [0, 72, 72, 72, 72],
                meter: [0, 5974, 5407, 5112, 4417]
            },
            'first-9': {
                cr: [0, 38.1, 37.0, 35.6, 33.4],
                sr: [0, 139, 131, 129, 115],
                par: [0, 35, 35, 35, 35],
                meter: [0, 2800, 2601, 2380, 2045]
            },
            'second-9': {
                cr: [0, 41.3, 38.5, 38.1, 36.2],
                sr: [0, 152, 139, 140, 127],
                par: [0, 37, 37, 37, 37],
                meter: [0, 3174, 2806, 2732, 2372]
            },
            'par-9-holes': {
                cr: [0, 0, 0, 25.4, 0],
                sr: [0, 0, 0, 77, 0],
                par: [0, 0, 0, 27, 0],
                meter: [0, 0, 0, 0, 0]
            },
            'par-18-holes': {
                cr: [0, 0, 0, 50.8, 0],
                sr: [0, 0, 0, 77, 0],
                par: [0, 0, 0, 54, 0],
                meter: [0, 0, 0, 0, 0]
            }
        }
    };

    const [gender, setGender] = useState(null);
    const [handicap, setHandicap] = useState('');
    const [lus, setLus] = useState(null);
    const [holeHandicaps, setHoleHandicaps] = useState([]);
    const [dataAvailable, setDataAvailable] = useState(false);
    const [showNineHoles, setShowNineHoles] = useState(false);
    const [showParHoles, setShowParHoles] = useState(false);

    const handleInputChange = (event) => {
        setHandicap(event.target.value);
    }

    const handleGenderSelect = (selectedGender) => {
        setGender(selectedGender);
        setShowParHoles(false);
        setShowNineHoles(false);
        setLus(null);
        setDataAvailable(false);
    };

    const handleLusSelect = (selectedLus) => {
        setLus(selectedLus);
        calculateHoleHandicaps(selectedLus);
    };

    const getTeeIndex = (handicap, gender, lus) => {
        handicap = parseInt(handicap);

        if(lus === 'par-9-holes' || lus === 'par-18-holes'){
            return 3;
        }else{
            if(gender === 'male'){
                if (handicap <= 10) return 0;
                if (handicap > 10 && handicap <= 28) return 1;
                if (handicap > 28 && handicap <= 36) return 2;
                if (handicap > 36 && handicap <= 54) return 3;
                if (handicap > 54 ) return 4;
            }else{
                if (handicap <= 5) return 1;
                if (handicap > 5 && handicap <= 15) return 2;
                if (handicap > 15 && handicap <= 45) return 3;
                if (handicap > 45 ) return 4;
            }
        }
    };

    const calculateHoleHandicaps = (selectedLus) => {
        const lus = selectedLus;

        if (gender && lus && handicap) {
            const teeIndex = getTeeIndex(handicap, gender, lus);
            const selectedCourse = courseData[gender][lus];

            const handicaps = selectedCourse.cr.map((_, index) => {
                return {
                    cr: selectedCourse.cr[index],
                    sr: selectedCourse.sr[index],
                    par: selectedCourse.par[index],
                    tee: teeIndex,
                    adjustedHandicap: calculateAdjustedHandicap(selectedCourse.cr[index], selectedCourse.sr[index], selectedCourse.par[index], handicap, lus),
                    meter: selectedCourse.meter[index],
                };
            });

            setHoleHandicaps(handicaps);
            setDataAvailable(true);
        } else {
            alert("Please select both gender and play type.");
            setDataAvailable(false);
        }
    };

    const calculateAdjustedHandicap = (selectedCr, selectedSr, selectedPar, handicap, lus) => {
        let holeHandicap = null;
        const parsedHandicap = parseFloat(handicap);
        if (lus !== '18-holes' && lus !== 'par-18-holes') {
            holeHandicap = Math.round((parsedHandicap / 2) * (selectedSr / 113) + (selectedCr - selectedPar));
        } else {
            holeHandicap = Math.round(parsedHandicap * (selectedSr / 113) + (selectedCr - selectedPar));
        }
        return holeHandicap < 0 ? `+${Math.abs(holeHandicap)}` : holeHandicap;
    };

    return (
        <div className="container">
            <GolfDisplayComponent golfData={dataAvailable ? holeHandicaps : []}/>
            <div className="flex flex-bottom">
                <div className="form">
                    <div className="form-steps">
                        <div className="line"></div>
                        {gender ? (
                            lus ? (
                                lus === '18-holes' ? (
                                    <p className="form-p">KLAAR</p>
                                ) : (
                                    <p className="form-p">KLAAR</p>
                                )
                            ) : (
                                (showNineHoles || showParHoles) ? (
                                    <p className="form-p">STAP 3</p>
                                ) : (
                                    <p className="form-p">STAP 2</p>
                                )
                            )
                        ) : (
                            <p className="form-p">STAP 1</p>
                        )}
                        <div className="line"></div>
                    </div>
                    <div className="margin">
                        <input
                            className="form-input"
                            type="number"
                            min="-4"
                            max="55"
                            inputMode="numeric"
                            placeholder="Voer je handicap in"
                            onFocus={() => {
                                setHandicap('');
                                setGender(null);
                                setShowParHoles(false);
                                setShowNineHoles(false);
                                setLus(null);
                                setDataAvailable(false);
                            }}
                            value={handicap}
                            onChange={handleInputChange}
                        />

                        <div className="button-container">
                            <div className="button--primary">
                                <button className={`button ${gender === 'male' ? 'clicked' : ''}`}
                                        onClick={() => handleGenderSelect('male')}>HEREN
                                </button>
                            </div>
                            <div className="button--primary">
                                <button className={`button ${gender === 'female' ? 'clicked' : ''}`}
                                        onClick={() => handleGenderSelect('female')}>DAMES
                                </button>
                            </div>
                        </div>

                        {gender && (
                            <>
                                <div className="button-container fade-in">
                                    <div className="button--primary">
                                        <button
                                            className={`button ${lus === '18-holes' && showNineHoles === false && showParHoles === false ? 'clicked' : ''}`}
                                            onClick={() => {
                                                handleLusSelect('18-holes')
                                                setShowParHoles(false);
                                                setShowNineHoles(false);
                                            }}
                                        >
                                            18 HOLES
                                        </button>
                                    </div>
                                    <div className="button--primary">
                                        <button className={`button ${showNineHoles === true ? 'clicked' : ''}`}
                                                onClick={() => {
                                                    setShowNineHoles(!showNineHoles);
                                                    setShowParHoles(false);
                                                    setDataAvailable(false);
                                                    setLus(false);
                                                }}
                                        >
                                            {showNineHoles ? '9 HOLES' : '9 HOLES'}
                                        </button>
                                    </div>
                                    <div className="button--primary">
                                        <button className={`button ${showParHoles === true ? 'clicked' : ''}`}
                                                onClick={() => {
                                                    setShowParHoles(!showParHoles);
                                                    setShowNineHoles(false);
                                                    setDataAvailable(false);
                                                    setLus(false);
                                                }}
                                        >
                                            {showParHoles ? 'PAR 3' : 'PAR 3'}
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    {showNineHoles ? (
                                        <>
                                            <div className="button-container fade-in">
                                                <div className="button--primary">
                                                    <button className={`button ${lus === 'first-9' ? 'clicked' : ''}`}
                                                            onClick={() => handleLusSelect('first-9')}>1e 9 HOLES
                                                    </button>
                                                </div>
                                                <div className="button--primary">
                                                    <button className={`button ${lus === 'second-9' ? 'clicked' : ''}`}
                                                            onClick={() => handleLusSelect('second-9')}>2e 9 HOLES
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                    {showParHoles ? (
                                        <>
                                            <div className="button-container fade-in">
                                                <div className="button--primary">
                                                    <button
                                                        className={`button ${lus === 'par-18-holes' ? 'clicked' : ''}`}
                                                        onClick={() => handleLusSelect('par-18-holes')}>PAR3 18 HOLES
                                                    </button>
                                                </div>
                                                <div className="button--primary">
                                                    <button
                                                        className={`button ${lus === 'par-9-holes' ? 'clicked' : ''}`}
                                                        onClick={() => handleLusSelect('par-9-holes')}>PAR3 9 HOLES
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GolfTeeComponent;
